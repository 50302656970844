import { Component, OnInit } from '@angular/core';
import { Ecommerce_Config } from '../Ecommerce_Config';
import { AlertController, PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profilepopover',
  templateUrl: './profilepopover.page.html',
  styleUrls: ['./profilepopover.page.scss'],
})
export class ProfilepopoverPage implements OnInit {
  user_id: string = window.localStorage.getItem(Ecommerce_Config.session_prefix + 'ad_user_id');
    
  constructor(public alertController: AlertController, private route: Router, public popover: PopoverController) { }

  ngOnInit() {
  }

  goToPage(page) {
    this.popover.dismiss();
    this.route.navigate(['/' + page]);
  }

  async logoutUser() {
    this.popover.dismiss();
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: "Are you sure?",
      message: "Do you really want to logout?",
      buttons: [
        {
          text: 'No',
          handler: () => {
            console.log('Confirm No');
          }
        },
        {
          text: 'Yes',
          handler: () => {
            window.localStorage.removeItem(Ecommerce_Config.session_prefix + 'ad_user_id');
            this.route.navigate(['/home']);
          }
        }
      ]
    });
    await alert.present();
  }

}
