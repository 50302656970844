import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Ecommerce_Config } from '../Ecommerce_Config';
import { RestApiService } from '../rest-api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  data: any = null;

  constructor(public restApi: RestApiService, private route: Router) { }

  ngOnInit() {
    this.getOrgDetails();
  }

  goToPage(page) {
    this.route.navigate(['/' + page]);
  }

  getOrgDetails() {
    this.restApi.getOrgDetails().subscribe((data: any) => {
      if (data['error'] == false) {
        this.data = data['data']['organization'];
      }
    })
  }

}
