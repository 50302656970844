import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './header.component';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

@NgModule({
    imports: [CommonModule, IonicModule, FormsModule, RouterModule],
    exports: [
        HeaderComponent
    ],
    declarations: [HeaderComponent],
})
export class HeaderModule { }