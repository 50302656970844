import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

@NgModule({
    imports: [CommonModule, IonicModule, RouterModule],
    exports: [
        FooterComponent
    ],
    declarations: [FooterComponent],
})
export class FooterModule { }